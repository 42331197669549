.list-div {
  width: 100% !important;
  position: sticky;
  top: 0;
  background-color: #49dcf3;
  box-sizing: border-box;
  padding-top: 2rem;
}
.edit-link {
  text-decoration-line: none !important;
  color: #0d0727;
}
.edit-link:hover {
  text-decoration-line: none !important;
  color: #f5f5f5;
}
.edit-links {
  text-decoration-line: none !important;
  color: #f5f5f5;
  font-weight: bold;
}
.header-div {
  width: 100% !important;
  position: sticky;
  top: 0;
  background-color: #49dcf3;
  box-sizing: border-box;
}
.header-div1 {
  width: 100% !important;
  position: fixed !important;
  top: 0 !important;
  background-color: #49dcf3 !important;
  box-sizing: border-box !important;
}
li {
  margin: 2.5rem;
}

.first-list {
  padding: 1rem 5rem 1rem 5rem;
  height: 4rem;
  margin-left: 0rem;
  border: 1.5px solid #49dcf3;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  text-decoration-line: none;
}
.first-list:hover {
  height: 4rem;
  background-color: #002e5b;
  color: white;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}

.second-list {
  padding: 1rem 5rem 1rem 5rem;
  height: 4rem !important;
  border: 1.5px solid #49dcf3;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
}
.second-list:hover {
  height: 4rem;
  background-color: #002e5b;
  color: white;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}
.third-list {
  padding: 1rem 5rem 1rem 5rem;
  height: 4rem;
  border: 1.5px solid #49dcf3;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
}
.third-list:hover {
  height: 4rem;
  background-color: #002e5b;
  color: white;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}
.fourth-list {
  padding: 1rem 5rem 1rem 5rem;
  height: 4rem;
  border: 1.5px solid #49dcf3;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
}
.navlogo {
  width: 20rem;
  border-radius: 360px;
  margin-left: rem;
}
.header-coinstick {
  font-weight: bolder;
  color: #0d0727;
  font-family: sans-serif;
  font-size: x-large;
  margin-top: -1rem;
}
.fourth-list:hover {
  height: 4rem;
  background-color: #002e5b;
  color: white;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}
.navlogo2 {
  display: none;
  /*width: 7rem;*/
}

.logo-flex {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  /*width: 20rem;*/
}
.logo-flex .first-list .second-list .third-list .fourth-list {
  display: flex;
  flex-direction: row;
}
.list-div2 {
  display: none;
}
.list-btn1 {
  margin-left: 3rem;
  height: 4rem;
  padding: 1rem 1rem 1rem 1rem;
  /*background-color: #fcb41c;*/
  background-color: #fcb41c;
  color: black;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}
.list-btn {
  margin-left: 3rem;
  height: 4rem;
  padding: 1rem 1rem 1rem 1rem;
  /*background-color: #fcb41c;*/
  background-color: #fcb41c;
  color: black;
  border: 1.5px solid #11bcd6;
  border-radius: 7px;
}
.list-btn-login {
  margin-left: 3rem;
  height: 4rem;
  padding: 1rem 1rem 1rem 1rem;
  /*background-color: #fcb41c;*/
  background-color: #49dcf3;
  color: white;
  border: 1.5px solid #49dcf3;
  border-radius: 7px;
}
.nav-rearrangement {
  display: none;
}
.how-link {
  text-decoration-line: none;
}

@media only screen and (min-width: 270px) and (max-width: 768px) {
  /*[class*="col-"] {*/
  /*    width: 100%;*/
  /*}*/
  /*.header-div{*/
  /*    display: none;*/
  /*}*/
  /*.nav-rearrangement{*/
  /*    display: inline-block;*/
  /*    !*gap: 70%;*!*/
  /*    width: 100%!important;*/
  /*    position: fixed;*/
  /*    top: 0;*/
  /*    !*padding-top: 1rem;*!*/
  /*    padding-bottom: 1rem;*/
  /*    background-color: white;*/
  /*    box-sizing: border-box;*/
  /*}*/
  /*.navbar1{*/
  /*    display: inline-block;*/
  /*    justify-content: flex-start;*/
  /*    align-items: center;*/
  /*    float: right;*/
  /*    margin-top: -1rem;*/
  /*    !*right: 90%;*!*/
  /*    !*margin-left: 15rem;*!*/
  /*    !*margin-right: -0rem;*!*/
  /*    !*right: -10%;*!*/

  /*}*/
  /*.fa-big{*/
  /*    width: 10rem;*/
  /*    height: 3.2rem;*/
  /*    margin-top: 1.25rem;*/
  /*    !*float: right;*!*/
  /*}*/
  /**{*/
  /*    !*background-color: #49dcf3;*!*/
  /*}*/
  /*.span-title{*/
  /*    float: right;*/
  /*}*/

  /*.menu-bars{*/
  /*    margin-left: 2rem;*/
  /*    !*width: 30rem;*!*/
  /*    font-size: 2rem;*/
  /*    background: none;*/
  /*}*/
  /*.nav-menu{*/
  /*    background-color: #002e5b;*/
  /*    width: 30rem;*/
  /*    height: 100vh;*/
  /*    display: flex;*/
  /*    justify-content: center;*/
  /*    position: fixed;*/
  /*    top: 0;*/
  /*    right: -100%;*/
  /*    transition: 850ms;*/
  /*}*/
  /*.nav-menu.active{*/
  /*    right: 0;*/
  /*    transition: 350ms;*/
  /*}*/
  /*.nav-text{*/
  /*    display: flex;*/
  /*    justify-content: flex-start;*/
  /*    align-items: center;*/
  /*    padding: 8px 0px 8px 0px;*/
  /*    list-style: none;*/
  /*    height: 60px;*/
  /*    width: 30rem;*/
  /*}*/
  /*.nav-text li {*/
  /*    text-decoration: none;*/
  /*    color: #f5f5f5;*/
  /*    font-size: 14px;*/
  /*    width: 95%;*/
  /*    height: 100%;*/
  /*    display: flex;*/
  /*    align-items: center;*/
  /*    padding: 0 16px;*/
  /*    border-radius: 4px;*/
  /*}*/
  /*.nav-div{*/
  /*    color: white;*/
  /*}*/
  /*.how-it-work .about .career .contact-us .ambassador{*/
  /*    display: flex;*/
  /*    justify-content: flex-start;*/
  /*    align-items: center;*/
  /*    padding: 8px 0px 8px 0px;*/
  /*    list-style: none;*/
  /*    height: 60px;*/
  /*    width: 30rem;*/
  /*}*/
  /*.how-it-work .about .career .contact-us .ambassador:hover{*/
  /*    background-color: #1a83ff;*/
  /*    width: 100%;*/
  /*}*/
  /*.edit-link-pro{*/
  /*    text-decoration-line: none!important;*/
  /*    color: white;*/
  /*}*/
  /*.ambassador1{*/
  /*    background-color: #fcb41c;*/
  /*    color: #0d0727;*/
  /*    height: 3.5rem;*/
  /*    border: 1px solid #fcb41c;*/
  /*    border-radius: 4px;*/
  /*    padding-left: 4px;*/
  /*    padding-right: 4px;*/
  /*}*/
  /*.nav-text a:hover{*/
  /*    background-color: #1a83ff;*/
  /*    width: 100%;*/
  /*    !*margin-right: 2rem;*!*/
  /*}*/
  /*.nav-rearrange{*/
  /*    margin-top: 1rem;*/
  /*}*/
  /*.nav-logo{*/
  /*    border-radius: 1rem;*/
  /*    margin-top: 10px;*/
  /*    padding-bottom: 3px;*/
  /*    margin-left: 6%;*/
  /*    margin-top: 1rem;*/
  /*    !*padding-top: 1rem;*!*/
  /*    !*margin-right:  15rem;*!*/
  /*    !*padding-left: 0;*!*/
  /*    !*padding-left: 0;*!*/
  /*    !*position: relative;*!*/
  /*    width: 15rem;*/
  /*    !*height: 4rem;*!*/
  /*}*/
  /*.navbar-coinstick{*/
  /*    font-weight: bolder;*/
  /*    !*line-height: 2;*!*/
  /*    color: #0d0727;*/
  /*    font-family: sans-serif;*/
  /*    font-size: x-large;*/
  /*    !*margin-top: 1rem;*!*/
  /*    margin-left: -1rem;*/
  /*}*/
  /*.nav-menu-items{*/
  /*    width: 100%;*/
  /*    !*margin-left: 240rem;*!*/
  /*    !*color: black;*!*/
  /*    !*left: 70%;*!*/
  /*    !*right: 0;*!*/
  /*}*/
  /*.navbar-toggle{*/
  /*    background-color: #002e5b;*/
  /*    width: 95%;*/
  /*    height: 80px;*/
  /*    display: flex;*/
  /*    justify-content: flex-start;*/
  /*    align-items: center;*/
  /*}*/
  /*span{*/
  /*    margin-left: 16px;*/
  /*}*/
}
/*// X-Small devices (portrait phones, less than 576px)*/
@media only screen and (max-width: 575.98px) {
  [class*="col-"] {
    width: 100%;
  }
  .header-div {
    display: none;
  }
  #header-div {
    display: none;
  }

  .nav-rearrangement {
    display: inline-block;
    width: 100% !important;
    position: fixed;
    top: 0;
    right: 0;

    padding-bottom: 5px;
    padding-top: 5px;
    background-color: white;
    box-sizing: border-box;
    overflow-x: hidden;
    transition: 10s;
  }
  /*  */

  /*  */
  .navbar1 {
    /* display: flex; */
    justify-content: flex-start;

    align-items: center;
    float: right !important;
    margin-top: 1rem;
    margin-right: -1.7rem;
    /*right: 90%;*/
    /* margin-left: 15rem; */
    /*right: -10%;*/
  }
  .fa-big {
    border-radius: 1rem;
    padding-bottom: 3px;
    margin-right: 6%;
    margin-top: 2.2rem;
    height: 2.5rem;
    width: 7rem;
  }

  .span-title {
    float: right;
  }

  .menu-bars {
    /* margin-left: 2rem; */
    /*width: 30rem;*/
    font-size: 2rem;
    background: none;
  }
  .nav-menu {
    background-color: #49dcf3;
    width: 80%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
  }
  .nav-text li {
    text-decoration: none;
    color: black;
    font-size: 14px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  .nav-div {
    color: white;
    margin-left: 25px;
    margin-top: 25px;
  }
  .how-it-work {
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 90%;
  }

  .how-it-work .about .career .contact-us .ambassador {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
  }
  .how-it-work .about .career .contact-us .ambassador:hover {
    background-color: #1a83ff;
    width: 100%;
  }
  .edit-link-pro {
    text-decoration-line: none !important;
    color: black;
  }
  .ambassador1 {
    background-color: #fcb41c;
    color: #0d0727;
    height: 3.5rem;
    border: 1px solid #fcb41c;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .nav-text a:hover {
    background-color: #1a83ff;
    width: 100%;
    /*margin-right: 2rem;*/
  }
  .nav-rearrange {
    margin-top: 0px;
  }
  .fa-big {
    border-radius: 1rem;
    padding-bottom: 3px;
    margin-right: 6%;
    margin-top: 2.2rem;
    height: 2.5rem;
    width: 7rem;
  }
  /* .fa-big {
    width: 7rem;
    height: 2.5rem;
    margin-top: 1.25rem;
  } */
  .navbar-coinstick {
    font-weight: bolder;
    /*line-height: 2;*/
    color: #0d0727;
    font-family: sans-serif;
    font-size: x-large;
    /*margin-top: 1rem;*/
    margin-left: -1rem;
  }
  .nav-menu-items {
    width: 100%;
    /*margin-left: 240rem;*/
    /*color: black;*/
    /*left: 70%;*/
    /*right: 0;*/
  }
  .navbar-toggle {
    /* background-color: #002e5b; */
    width: 100%;
    margin-top: -1rem;
    /* height: 80px; */
    display: flex;
    margin-right: 0 !important;
    margin-top: 0 !important;
    /* justify-content: flex-start;
        align-items: center; */
  }
  span {
    margin-left: 16px;
  }
}

/*// Small devices (landscape phones, less than 768px)*/
@media only screen and (max-width: 767.98px) {
  [class*="col-"] {
    width: 100%;
  }
  .header-div {
    display: none;
  }
  .nav-rearrangement {
    display: inline-block;
    /*gap: 70%;*/
    width: 100% !important;
    position: fixed;
    top: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: white;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: 1;
  }
  .navbar1 {
    /* display: inline-block; */
    justify-content: flex-start;
    align-items: center;
    float: right;
    margin-top: -1rem;
    /* margin-right: -1.7rem; */
    /*right: 90%;*/
    /*margin-left: 15rem;*/
    /*margin-right: -0rem;*/
    /*right: -10%;*/
  }
  .fa-big {
    border-radius: 1rem;
    padding-bottom: 3px;
    margin-right: 6%;
    margin-top: 2.2rem;
    height: 2.5rem;
    width: 7rem;
  }

  .span-title {
    float: right;
  }

  .menu-bars {
    margin-left: 2rem;
    /*width: 30rem;*/
    font-size: 2rem;
    background: none;
  }
  .nav-menu {
    background-color: #002e5b;
    width: 80%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850;
  }
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
  }
  .nav-text li {
    text-decoration: none;
    color: black;
    font-size: 14px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  .nav-div {
    color: white;
  }
  .how-it-work .about .career .contact-us .ambassador {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
  }
  .how-it-work .about .career .contact-us .ambassador:hover {
    background-color: #1a83ff;
    width: 100%;
  }
  .edit-link-pro {
    text-decoration-line: none !important;
    color: black;
  }
  .ambassador1 {
    background-color: #fcb41c;
    color: white;
    height: 3.5rem;
    border: 1px solid #fcb41c;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .ambassador2 {
    background-color: #002e5b;
    color: white;
    height: 3.5rem;
    border: 1px solid #fcb41c;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: 25rem;
  }
  .ambassador3 {
    background-color: #fcb41c;
    color: white;
    height: 3.5rem;
    border: 1px solid #fcb41c;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: 25rem;
  }
  .nav-text a:hover {
    background-color: #1a83ff;
    width: 100%;
    /*margin-right: 2rem;*/
  }
  .nav-rearrange {
    margin-top: 0px;
  }
  .nav-logo {
    border-radius: 1rem;
    margin-top: 10px;
    padding-bottom: 3px;
    margin-left: 6%;
    margin-top: 1.25rem;
    /*padding-top: 1rem;*/
    /*margin-right:  15rem;*/
    /*padding-left: 0;*/
    /*padding-left: 0;*/
    /*position: relative;*/
    width: 10rem;
    /*height: 4rem;*/
  }
  .navbar-coinstick {
    font-weight: bolder;
    /*line-height: 2;*/
    color: #0d0727;
    font-family: sans-serif;
    font-size: x-large;
    /*margin-top: 1rem;*/
    margin-left: -1rem;
  }
  .nav-menu-items {
    width: 100%;
    /*margin-left: 240rem;*/
    /*color: black;*/
    /*left: 70%;*/
    /*right: 0;*/
  }
  .navbar-toggle {
    /* background-color: #002e5b; */
    width: 100%;
    /* height: 80px; */
    margin-top: -1rem;
    display: flex;
    margin-top: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
    /* justify-content: flex-start;
        align-items: center; */
  }
  span {
    margin-left: 16px;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media only screen and (max-width: 991.98px) {
  [class*="col-"] {
    width: 100%;
  }
  .header-div {
    display: none;
  }
  .nav-rearrangement {
    display: inline-block;
    /*gap: 70%;*/
    width: 100% !important;
    position: fixed;
    top: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: white;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  .navbar1 {
    /* display: inline-block; */
    justify-content: flex-start;
    align-items: center;
    float: right;
    margin-top: -1rem;
    /* margin-right: -1.7rem; */
    /*right: 90%;*/
    /*margin-left: 15rem;*/
    /*margin-right: -0rem;*/
    /*right: -10%;*/
  }
  .fa-big {
    border-radius: 1rem;
    padding-bottom: 3px;
    margin-right: 1rem;
    margin-top: 2.2rem;
    height: 2.5rem;
    width: 7rem;
  }

  .span-title {
    float: right;
  }

  .menu-bars {
    margin-left: 2rem;
    /*width: 30rem;*/
    font-size: 2rem;
    background: none;
  }
  .nav-menu {
    background-color: #002e5b;
    width: 80%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
  }
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
    color: black;
  }
  li {
    color: black;
  }
  .nav-text li {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  .nav-div {
    color: white;
  }
  .how-it-work .about .career .contact-us .ambassador {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
    width: 30rem;
  }
  .how-it-work .about .career .contact-us .ambassador:hover {
    background-color: #1a83ff;
    width: 100%;
  }
  .edit-link-pro {
    text-decoration-line: none !important;
    color: white;
  }
  .ambassador1 {
    background-color: #fcb41c;
    color: #0d0727;
    height: 3.5rem;
    border: 1px solid #fcb41c;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .nav-text a:hover {
    /* background-color: #1a83ff; */
    width: 100%;
    /*margin-right: 2rem;*/
  }
  .nav-rearrange {
    margin-top: 0px;
  }
  .nav-logo {
    border-radius: 1rem;
    margin-top: 10px;
    padding-bottom: 3px;
    margin-left: 6%;
    margin-top: 1.25rem;
    /*padding-top: 1rem;*/
    /*margin-right:  15rem;*/
    /*padding-left: 0;*/
    /*padding-left: 0;*/
    /*position: relative;*/
    width: 10rem;
    /*height: 4rem;*/
  }
  .navbar-coinstick {
    font-weight: bolder;
    /*line-height: 2;*/
    color: #0d0727;
    font-family: sans-serif;
    font-size: x-large;
    /*margin-top: 1rem;*/
    margin-left: -1rem;
  }
  .nav-menu-items {
    width: 100%;
    /*margin-left: 240rem;*/
    /*color: black;*/
    /*left: 70%;*/
    /*right: 0;*/
  }
  .navbar-toggle {
    /* background-color: #002e5b; */
    width: 100%;
    margin-top: -1rem;
    height: 80px;
    margin: 0;
    display: flex;
    /* justify-content: flex-start;
        align-items: center; */
  }
  span {
    margin-left: 16px;
  }
}

/*@media only screen and (min-width: 350px) and (max-width: 768px) {*/
/*    .navbar1 {*/

/*        justify-content: start;*/
/*        align-items: center;*/
/*        !*margin-left: 19rem;*!*/
/*    }*/

/*}*/
/*@media only screen and  (max-width: 351px) {*/
/*    .navbar1 {*/
/*        justify-content: start;*/
/*        align-items: center;*/
/*        !*float: right;*!*/
/*        margin-left: 51%;*/
/*    }*/

/*}*/
