.homediv {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 30px;
}

.headerdiv {
  margin-left: 5%;
  margin-right: 5%;
  background-color: #34e5eb;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 85%;
  overflow: hidden;
  z-index: 1;

  /* padding-right: 30px; */
  /* margin-top: 2%; */
}
.innerHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 500px) {
  .homediv {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 30px;
  }

  .headerdiv {
    display: none;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #34e5eb;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 85%;
    overflow: hidden;
    z-index: 1;

    /* padding-right: 30px; */
    /* margin-top: 2%; */
  }
  .innerHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
