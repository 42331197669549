.dropbtn {
  font-size: 18px !important;
  font-weight: bold !important;
  width: 10rem;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
