.review-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.review-item img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-text {
  font-size: 16px;
  text-align: center;
}
