.maindiv {
  margin-left: 5%;
  margin-right: 5%;
  /* width: 100%; */
}
.sectiondiv {
  margin-left: 5%;
  margin-right: 5%;
  /* width: 100%; */
  /* display: none; */
}
.header {
  text-align: center;
}
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 30%;
  height: 400px;
  margin-top: 30px;
}
.allcard {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
  /* justify-content: space-between; */
  /* width: 100%; */
  /* display: none; */
}
.imagestyle {
  /* align-items: center; */
  text-align: center;
  height: 150px;
}
.contentdiv {
  /* height: 200px; */
  background-color: #34e5eb;
  color: white;
  height: 230px;
  padding-bottom: 5px;
}
.contentheader {
  font-size: 38px;
  font-weight: bold;
  margin-left: 10px;
  /* padding-top: 15px; */
  padding-top: 5px;
}

.contenttext {
  margin-left: 5px;
  font-weight: 600;
  font-size: 18px;
}
.buttonstyle {
  margin-top: 80px;
  margin-left: 10%;
}
.hereisthedeal {
  /* display: none !important; */
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
}
.thedealimg {
  width: 420px;
  height: 420px;
  margin-top: 40px;
}
.thedealtextdiv {
  width: 55%;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
}
.giftcarddiv {
  /* display: none !important; */
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 2%;
}
.giftcardimg {
  width: 90%;
  margin-top: -35%;
  /* margin-bottom: -15%; */
}
.giftcardtextdiv {
  width: 50%;
  font-size: 18px;
}
.giftcardtext {
  margin-top: 50px;
  font-size: 18px;
}
.sellgiftcardimgdiv {
  align-items: center;
  width: 40%;
}
.sectionlightorange {
  /* display: none; */
  background-color: #fffefa;
  height: 400px;
  width: 100%;
  margin-bottom: 0rem;
}
.sectionlightorangeimage {
  width: 90%;
  /* margin-top: -35%; */
  margin-bottom: -55%;
  /* object-position: center;
  object-fit: scale-down; */
}
.sectionlightorangediv {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
}
.sell_giftcardheaderimg {
  margin-top: -20%;
  margin-bottom: -20%;
  width: 50%;
  /* padding: 0;
  margin: 0; */

  /* height: 250px; */
  /* object-position: center; */
  /* object-fit: scale-down; */
}
.hereisthedealimg {
  width: 95%;
  /* object-position: center;
  object-fit: scale-down; */
  align-self: flex-start;
  margin-left: -15%;
}
.giftcarddivimg {
  /* max-height: 360px; */
  width: 80%;
  /* margin-top: -35%; */
  margin-bottom: -35%;
  margin-left: -3%;
}
.quicklyimg {
  width: 80%;
  margin-left: -3%;
  margin-bottom: -5%;
  /* object-position: center;
  object-fit: scale-down; */
}
@media (max-width: 600px) {
  .maindiv {
    margin-left: 5%;
    margin-right: 5%;
    /* width: 100%; */
  }

  .sectiondiv {
    margin-left: 2%;
    margin-right: 2%;
    width: 100%;
    /* display: none; */
  }
  .header {
    text-align: center;
  }
  .sell_giftcardheaderimg {
    margin-top: -35%;
    margin-bottom: -35%;
    width: 90%;
    /* padding: 0;
    margin: 0; */

    /* height: 250px; */
    /* object-position: center; */
    /* object-fit: scale-down; */
  }
  .sellgiftcardimgdiv {
    align-items: center;
    width: 100%;
  }
  .sectionlightorangeimage {
    width: 90%;
    margin-top: -35%;
    margin-bottom: -55%;
    /* object-position: center;
    object-fit: scale-down; */
  }
  .card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    width: 90%;
    height: 400px;
    margin-top: 60px;
    /* display: none; */
  }
  .allcard {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* margin-left: 10%; */
    /* margin-right: 10%; */
    /* width: 100%; */
    margin-left: 2%;
    margin-right: 2%;
  }
  .imagestyle {
    /* align-items: center; */
    text-align: center;
    height: 150px;
  }
  .contentdiv {
    /* height: 200px; */
    background-color: #22aacc;
    color: white;
    height: 230px;
    padding-bottom: 5px;
  }
  .contentheader {
    font-size: 38px;
    font-weight: bold;
    /* margin-left: 10px; */
    /* padding-top: 15px; */
    padding-top: 5px;
  }

  .contenttext {
    /* margin-left: 5px; */
    font-weight: 600;
    font-size: 18px;
  }
  .buttonstyle {
    display: none;
    margin-top: 80px;
    margin-left: 10%;
  }
  .hereisthedeal {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .thedealimg {
    width: 95%;
    max-height: 360px;
    /* width: 420px;
    height: 420px;
    margin-top: 40px; */
  }
  .thedealtextdiv {
    width: 95%;
    font-size: 18px;
    line-height: 23px;
    color: #333333;
    margin-top: 30px;
  }
  .hereisthedealimg {
    width: 95%;
    /* object-position: center;
    object-fit: scale-down; */
    align-self: flex-start;
    margin-left: -15%;
  }
  .giftcarddiv {
    margin-top: 100px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    width: 100%;
    margin-left: 2%;
    /* margin-right: 2%; */
  }

  .giftcarddivimg {
    max-height: 360px;
    width: 90%;
    margin-top: -35%;
    margin-bottom: -43%;
    margin-left: -3%;
  }
  .giftcardtextdiv {
    width: 95%;
    font-size: 18px;
  }
  .giftcardtext {
    margin-top: 50px;
    font-size: 18px;
  }
  .sectionlightorange {
    background-color: #fffefa;
    height: 400px;
    width: 100%;
    /* margin-bottom: 25rem; */
  }
  .giftcardimg {
    width: 90%;
    margin-top: -45%;
    margin-bottom: -15%;
  }

  .sectionlightorangediv {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }
  .quicklyimg {
    width: 90%;
    margin-left: -3%;
    margin-bottom: -5%;
    /* object-position: center;
    object-fit: scale-down; */
  }
}
