@media only screen and (min-width: 300px) and (max-width: 350px) {
  .buyCircle1 {
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2rem !important;
    height: 2rem !important;
  }
  .webImg1 {
    max-width: 8rem;
    height: 4rem !important;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 351px) and (max-width: 400px) {
  .buyCircle1 {
    width: 27rem;
    height: 27rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .webImg1 {
    max-width: 10rem;
    height: 4rem !important;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 401px) and (max-width: 450px) {
  .buyCircle1 {
    max-width: 32rem;
    height: 32rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .webImg1 {
    max-width: 11rem;
    height: 4rem !important;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 500px) {
  .buyCircle1 {
    max-width: 37rem;
    height: 37rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .webImg1 {
    max-width: 13rem;
    height: 5rem !important;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 550px) {
  .buyCircle1 {
    max-width: 42rem;
    height: 42rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .webImg1 {
    max-width: 14rem;
    height: 5rem !important;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 600px) {
  .buyCircle1 {
    max-width: 47rem;
    height: 47rem;
    border-radius: 50%;
    background-color: rgba(34, 170, 204, 0.25);
    border-color: #22aacc;
    border: 1px solid #22aacc;
  }
  .buyCircle2 {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background-color: whitesmoke;
    border-color: #22aacc;
    border: 1px solid #22aacc;
    display: none;
  }
  .facebookLogo {
    border-radius: 50%;
    max-width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .webImg1 {
    max-width: 14rem;
    height: 5rem !important;
    border-radius: 5px;
  }
}

/* .buyCircle1{
    width:20rem;    
    height:20rem;
    border-radius:50%;
    backgroundColor:rgba(34, 170, 204, 0.25);
    borderColor:#22AACC;
    border:1px solid #22AACC;
} */

.buyCircle2 {
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  background-color: whitesmoke;
  border-color: #22aacc;
  border: 1px solid #22aacc;
}
