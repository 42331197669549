.maindiv {
  margin-top: 9%;
  margin-left: 5%;
  margin-right: 5%;
}
.alllinkDiv1 {
  display: none;
}

.sellgiftcard {
  font-size: 64px;
  font-weight: 800;
  color: #22aacc;
}
.sellgiftcardText {
  font-size: 18px;
  width: 80%;
}
.sellgiftcarddiv {
  width: 60%;
  padding-top: 7%;

  margin-top: -12vh;
}

.linkDiv {
  width: 160px;
  height: 60px;
  border-radius: 10px;
  background-color: #22aacc;
}
.alllinkDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 20px;
}
.sellgiftcardimg {
  width: 100%;
  margin-top: 10vh;
  /* height: 690px; */
}
.sellgiftcardimgdiv {
  /* align-items: center; */
  width: 40%;
}
.alldiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sellyourgiftcard {
  width: 90%;
  margin-bottom: 0%;
  margin-left: -2.5%;
  margin-bottom: -55px;
}

@media (max-width: 767px) {
  .maindiv {
    /* display: none; */
    margin-top: 7rem;
    margin-left: 0px !important;
    margin-right: 2%;
  }

  .sellgiftcard {
    font-size: 34px !important;
    font-weight: 800;
    color: #22aacc;
  }
  .sellgiftcardText {
    font-size: 18px;
    width: 100%;
  }
  .sellgiftcarddiv {
    width: 100%;
    padding-top: 7%;
    margin-left: 5%;

    margin-top: 0;
  }

  .linkDiv {
    width: 160px;
    height: 60px;
    border-radius: 10px;
    background-color: #22aacc;
  }
  .alllinkDiv {
    display: none;
    flex-direction: row;
    gap: 10px;
    padding-top: 40px;
  }
  .alllinkDiv1 {
    display: contents;
  }

  .linkDivinner {
    display: flex;
    flex-direction: row;
    /* gap: 10px; */
    justify-content: space-around;
    width: 100%;
    padding-top: 40px;
    padding-top: 0;
    margin-bottom: 1rem;
  }
  .whatsappstyle {
    padding-top: 15px;
  }
  .sellgiftcardimg {
    width: 95%;
    /* height: 350px; */
    margin-left: 5%;
    /* align-items: center; */
    margin-top: 0;
  }
  .sellgiftcardimgdiv {
    align-items: center;
    width: 100%;
  }
  .alldiv {
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: space-between; */
  }
  .storeimage {
    /* width: 90%;
    height: 50px; */
    /* padding-top: 20px; */
    /* padding-bottom: -50px; */
    width: 100%;
  }
  .storeimage1 {
    width: 55%;
  }
  .storediv {
    display: flex;
    flex-direction: column;
  }
  .sellyourgiftcard {
    width: 90%;
    margin-bottom: -10%;
    margin-left: -3%;
  }
  .whatsapplogo {
    /* width: 55%; */
    height: 35px;
    border-radius: 50%;
  }
  .whatsapplogolink {
    /* align-items: center; */
    /* padding: 10px; */
    /* border-radius: 10px; */
    /* width: 43%; */
    /* height: 50px; */
    /* justify-content: center; */
    /* margin-left: 4%; */
  }
  .whatsapplogodiv {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
